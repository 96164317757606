import {
  Configuration,
  PopupRequest,
  RedirectRequest,
  BrowserAuthOptions,
} from '@azure/msal-browser';
import { SessionType } from '../types/user.d';
import { IClientConfiguration } from '../features/client/clientApi.slice';

const { REACT_APP_REDIRECT_URI, REACT_APP_CLIENT_ID } = process.env;

const clientCode = window.location.host.split('.')[0];
const redirectUri = `https://${clientCode}.${REACT_APP_REDIRECT_URI}`;

export const msalConfig = (type: SessionType, config?: IClientConfiguration): Configuration => {
  const auth: BrowserAuthOptions = { redirectUri } as BrowserAuthOptions;
  if (type === SessionType.GUARDIAN && config?.hasPips) {
    if (!config.pipsId || !config.pipsAuthority) throw new Error('PIPs misconfiguration');
    auth.clientId = config.pipsId;
    auth.authority = config.pipsAuthority;
    auth.knownAuthorities = [new URL(config.pipsAuthority).origin];
  } else {
    auth.clientId = REACT_APP_CLIENT_ID ?? '';
    auth.authority = 'https://login.microsoftonline.com/common';
  }
  return {
    auth,
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
    // // TODO add system options to log relevant information when analytics are running
    // system: {
    //   loggerOptions: {
    //     loggerCallback: (level, message, containsPii) => {
    //       if (containsPii) return;
    //       switch (level) {
    //         case LogLevel.Error:
    //           console.error(message);
    //           return;
    //         case LogLevel.Info:
    //           console.info(message);
    //           return;
    //         case LogLevel.Verbose:
    //           console.debug(message);
    //           return;
    //         case LogLevel.Warning:
    //           console.warn(message);
    //           return;
    //         default:
    //           console.log(message);
    //       }
    //     },
    //   },
    // },
  };
};

export const msGraphRequest: PopupRequest = {
  scopes: ['User.Read'],
};

export const redirectRequest = (sessionType: SessionType): RedirectRequest => ({
  scopes: sessionType === SessionType.STAFF ? ['User.Read'] : [],
  redirectUri,
  redirectStartPage: redirectUri,
});
