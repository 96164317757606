import { useLocation } from 'react-router';
import { JSX, useEffect, useState } from 'react';
import { Accordion, Container } from 'react-bootstrap';
import { Sliders2, PersonLock, BoxArrowRight, HouseDoor } from 'react-bootstrap-icons';
import SignOutButton from '../Buttons/SignOutButton';
import { useAppSelector } from '../../features/hooks';
import NavButton, { NavType } from '../NavButton/NavButton';
import { IPage, ReportIconsMap } from '../../types/report.d';
import { useGetUserQuery } from '../../features/user/userApi.slice';
import { selectIsGuardianSession } from '../../features/auth/auth.slice';
import { selectShowMobileSidebar } from '../../features/layout/layout.slice';
import { selectCurrentPbiReportId } from '../../features/report/report.slice';
import { hasAdminAccess, isGlobalAdmin } from '../../utils/authorization.utils';

function Sidebar(): JSX.Element {
  const { data: user } = useGetUserQuery();
  const isGuardianSession = useAppSelector(selectIsGuardianSession);

  /* --------------- Sidebar expand and collapse --------------- */
  const showMobileSidebar = useAppSelector(selectShowMobileSidebar);

  /* --------------- Control the expanded accordion item --------------- */
  const currentPbiReportId = useAppSelector(selectCurrentPbiReportId);
  const [accordionActiveKey, setAccordionActiveKey] = useState<string | null | undefined>(null);

  /* Expand and collapse programatically */
  useEffect(() => {
    setAccordionActiveKey(currentPbiReportId);
  }, [currentPbiReportId]);

  /* --------------- Display active nav button --------------- */
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState<string>(location.pathname);

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location.pathname]);

  /* --------------- Sort report pages in the right order --------------- */
  const sortPagesByOrder = (pages: IPage[]): IPage[] => {
    const p = [...pages];
    return p.sort((a, b) => a.order - b.order);
  };

  return (
    <>
      <div id="sidebar-background" className={showMobileSidebar ? 'open' : ''} role="none" />
      <Container id="sidebar" className={showMobileSidebar ? '' : 'hidden'}>
        <div id="sidebarContent">
          <div id="sidebarTop">
            <NavButton
              BsIcon={HouseDoor}
              navType={NavType.MAIN}
              linkTo="/"
              isActive={currentLocation === '/'}
            >
              Home
            </NavButton>
            {!!user && hasAdminAccess(user.role, isGuardianSession) && (
              <NavButton
                BsIcon={Sliders2}
                navType={NavType.MAIN}
                linkTo="/settings"
                isActive={currentLocation === '/settings'}
              >
                Settings
              </NavButton>
            )}
            {!!user && isGlobalAdmin(user.role, isGuardianSession) && (
              <NavButton
                BsIcon={PersonLock}
                navType={NavType.MAIN}
                linkTo="/admin"
                isActive={currentLocation === '/admin'}
              >
                EduBI Admin
              </NavButton>
            )}
            <div className="user-groups-container">
              <Accordion className="group-accordion" activeKey={accordionActiveKey}>
                {user?.sections
                  .flatMap((s) => s.reports)
                  .map((report) => {
                    const basePath = `/powerbi/${report.powerbiId}/api/reports/${report.pbiReportId}`;
                    const basePage = sortPagesByOrder(report.pages)[0].name;
                    const icon = ReportIconsMap[report.icon];
                    return (
                      <NavButton
                        key={report.id}
                        BsIcon={icon}
                        navType={NavType.MAIN}
                        linkTo={`${basePath}/${basePage}`}
                        isActive={currentLocation.includes(report.pbiReportId)}
                      >
                        {report.displayName}
                      </NavButton>
                    );
                  })}
              </Accordion>
            </div>
          </div>
          <div className="signout-btn">
            <SignOutButton className="" variant="primary">
              <BoxArrowRight size={19} />
              <span className="signout-mobile-text">Log Out</span>
            </SignOutButton>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Sidebar;
