import { Button, Modal } from 'react-bootstrap';
import { JSX, useEffect, useRef, useState } from 'react';

export interface IdleModalProps {
  show: boolean;
  onContinueSelected: () => Promise<void>;
  onLeavePageSelected: () => void;
}

const { REACT_APP_IDLE_MODAL_TIMEOUT } = process.env;
const IDLE_MODAL_INTERVAL_TIME = REACT_APP_IDLE_MODAL_TIMEOUT || 59;

function IdleModal(props: IdleModalProps): JSX.Element {
  const timeoutInterval = useRef<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { show, onContinueSelected, onLeavePageSelected } = props;
  const [timeout, setTimeout] = useState<number>(+IDLE_MODAL_INTERVAL_TIME);

  const handleContinueSelected = async () => {
    setIsLoading(true);
    await onContinueSelected();
    setIsLoading(false);
  };

  useEffect(() => {
    if (show) setTimeout(+IDLE_MODAL_INTERVAL_TIME);
    else clearInterval(timeoutInterval.current);
  }, [show]);

  useEffect(() => {
    if (show) {
      timeoutInterval.current = window.setInterval(() => {
        setTimeout((time) => {
          if (time === 0) {
            onLeavePageSelected();
            return 0;
          }
          return time - 1;
        });
      }, 1000);
    }
  }, [show]);

  useEffect(() => {
    return () => {
      if (timeoutInterval.current) clearInterval(timeoutInterval.current);
    };
  }, []);

  return (
    <Modal className="idle-modal" show={show} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Are you still here?</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button disabled={isLoading} variant="primary" onClick={handleContinueSelected}>
          {isLoading
            ? 'Refreshing token...'
            : `Continue here ${`${Math.floor(timeout / 60)}`.padStart(2, '0')}:${`${
                timeout % 60
              }`.padStart(2, '0')}`}
        </Button>
        <Button disabled={isLoading} variant="secondary" onClick={onLeavePageSelected}>
          Go to homepage
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default IdleModal;
