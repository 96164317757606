import { IPublicClientApplication } from '@azure/msal-browser';
import { SessionType } from '../types/user.d';
import { redirectRequest } from '../config/authConfig';

export const azureSigninRedirect = async (
  instance: IPublicClientApplication,
  sessionType: SessionType
): Promise<void> => {
  return instance.loginRedirect(redirectRequest(sessionType));
};

export const azureSignout = (instance: IPublicClientApplication): Promise<void> => {
  return instance.logoutPopup();
};
