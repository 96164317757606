import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router';
import 'bootstrap/dist/css/bootstrap.min.css';
import { MsalProvider } from '@azure/msal-react';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import './index.scss';
import App from './App';
import store from './features/store';
import { SessionType } from './types/user.d';
import { msalConfig } from './config/authConfig';

/* EduBI Theme */
document.body.classList.add('edubi');

let configuration: Configuration;
const savedConfiguration = localStorage.getItem('msalConfig');
if (savedConfiguration) configuration = { auth: JSON.parse(savedConfiguration) };
else configuration = msalConfig(SessionType.STAFF);
const instance = new PublicClientApplication(configuration);

instance.initialize().then(() => {
  if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0)
    instance.setActiveAccount(instance.getAllAccounts()[0]);
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <React.StrictMode>
      <MsalProvider instance={instance}>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </MsalProvider>
    </React.StrictMode>
  );
});
