import { JSX, useEffect } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Route, Routes, useLocation, useNavigate } from 'react-router';

/* Utils */
import { hasAdminAccess, isGlobalAdmin } from './utils/authorization.utils';

/* Types */
import { SessionType } from './types/user.d';

/* Slices */
import {
  setSessionType,
  selectSessionType,
  selectIsGuardianSession,
} from './features/auth/auth.slice';
import {
  setErrorMessage,
  setIsPageLoading,
  setSuccessMessage,
  buildLoaderAction,
} from './features/layout/layout.slice';
import { useAppDispatch, useAppSelector } from './features/hooks';
import { useSigninMutation } from './features/auth/authApi.slice';
import { saveCurrentClient } from './features/client/client.slice';
import { useLazyGetUserQuery } from './features/user/userApi.slice';
import { saveCurrentUser, selectCurrentUser } from './features/user/user.slice';
import { useLazyGetClientConfigurationQuery } from './features/client/clientApi.slice';

/* Pages */
import Home from './pages/Home/Home';
import Admin from './pages/Admin/Admin';
import Signin from './pages/Signin/Signin';
import Report from './pages/Report/Report';
import Layout from './components/Layout/Layout';
import Redirect from './pages/Redirect/Redirect';
import Settings from './pages/Settings/Settings';
import NotFound from './pages/NotFound/NotFound';
import ExpiredSession from './pages/Expired/Expired';
import ServerError from './pages/ServerError/ServerError';
import Unauthorized from './pages/Unauthorized/Unauthorized';

function App(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { instance } = useMsal();
  const isAzureAuth = useIsAuthenticated();

  const [serviceSignin] = useSigninMutation();
  const [getCurrentUser] = useLazyGetUserQuery();
  const [getClientConfig] = useLazyGetClientConfigurationQuery();

  const currentUser = useAppSelector(selectCurrentUser);
  const sessionType = useAppSelector(selectSessionType);
  const isGuardianSession = useAppSelector(selectIsGuardianSession);

  /**
   * @param pathname current URL path
   * Checks it's authenticated against the service.
   * If yes, then will be able to retrieve the current user and save it in the state.
   * If not:
   *  - Will redirect to /unauthorized if it's been authenticated agains Azure.
   *  - Will redirect to /signin if it's not authenticated agains Azure.
   */
  const checkAuth = (pathname: string) => {
    dispatch(setIsPageLoading(buildLoaderAction(true)));
    getCurrentUser()
      .unwrap()
      .then((user) => {
        dispatch(saveCurrentUser(user));
        dispatch(saveCurrentClient(user.client));
        if (pathname === '/signin') navigate('/');
        document.body.classList.remove('not-auth');
        document.body.classList.add('is-auth');
      })
      .catch((error) => {
        document.body.classList.add('not-auth');
        document.body.classList.remove('is-auth');
        if (!isAzureAuth) navigate('/signin');
        else if (!currentUser) navigate('/unauthorized');
        else {
          switch (error.status) {
            case 401:
              navigate('/expired');
              break;
            case 403:
              navigate('/unauthorized');
              break;
            default:
              navigate('/server-error');
          }
        }
      })
      .finally(() => dispatch(setIsPageLoading(buildLoaderAction(false))));
  };

  /**
   * Handler for the MSAL redirect signin.
   *  1. Call handleRedirectPromise()
   *  2. Acquire token silently
   *  3. Execute service signin
   *  4. Save user into state
   *  5. Navigate to homepage
   */
  const handleRedirect = async (): Promise<void> => {
    dispatch(setIsPageLoading(buildLoaderAction(true)));
    getClientConfig()
      .unwrap()
      .then(({ appId, tenantId }) => {
        if (!appId || !tenantId) return;
        instance
          .handleRedirectPromise()
          .then((serviceToken) => {
            if (!serviceToken) {
              dispatch(setErrorMessage('Unable to sign in'));
              navigate('/unauthorized');
              return;
            }
            instance.setActiveAccount(serviceToken.account);
            serviceSignin({
              token: serviceToken.idToken,
              sessionType: (localStorage.getItem('SessionType') as SessionType) || sessionType,
            })
              .unwrap()
              .then(({ sessionType: type }) => {
                dispatch(setSessionType(type));
                localStorage.setItem('SessionType', type);
                getCurrentUser()
                  .unwrap()
                  .then((user) => {
                    dispatch(saveCurrentUser(user));
                    dispatch(saveCurrentClient(user.client));
                    document.body.classList.remove('not-auth');
                    document.body.classList.add('is-auth');
                    dispatch(setSuccessMessage('Signed in'));
                    navigate('/');
                  });
              })
              .catch(() => {
                navigate('/unauthorized');
                dispatch(setErrorMessage('Unable to sign in'));
              });
          })
          .catch(() => dispatch(setErrorMessage('Unable to sign in')))
          .finally(() => dispatch(setIsPageLoading(buildLoaderAction(false))));
      })
      .catch(() => navigate('/server-error'));
  };

  /**
   * On URL change, checks if user is already authenticated.
   * Handles redirect if URL = /redirect.
   */
  useEffect(() => {
    switch (location.pathname) {
      case '/redirect':
        handleRedirect();
        break;
      case '/unauthorized':
      case '/expired':
      case '/server-error':
        break;
      default:
        checkAuth(location.pathname);
    }
  }, [location.pathname]);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/redirect" element={<Redirect />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/powerbi/:powerbiId/api/reports/:pbiReportId/:pageName" element={<Report />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/expired" element={<ExpiredSession />} />
        <Route path="/server-error" element={<ServerError />} />
        {currentUser && hasAdminAccess(currentUser.role, isGuardianSession) && (
          <Route path="/settings" element={<Settings />} />
        )}
        {currentUser && isGlobalAdmin(currentUser.role, isGuardianSession) && (
          <Route path="/admin" element={<Admin />} />
        )}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
}

export default App;
