import { Search } from 'react-bootstrap-icons';
import { Button, Form, Spinner } from 'react-bootstrap';
import { JSX, useEffect, useRef, useState } from 'react';

interface SearchBoxProps {
  items: any[];
  itemDisplayKey: string; // The property key of the item whose value is the desired one to be displayed in the list
  itemKey: string; // The property of the item that will be used as a unique key on the rendered list
  limit?: number; // A limit on the amount of items shown in the result list
  searchLabel: string;
  isLoading?: boolean;
  onChange: (input: string) => void;
  onItemClick: (item: any) => void;
}

function SearchBox(props: SearchBoxProps): JSX.Element {
  const { items, itemDisplayKey, itemKey, limit, searchLabel, onChange, onItemClick, isLoading } =
    props;

  const [limitedItems, setLimitedItems] = useState<typeof items>([]);
  const [inputFocused, setInputFocused] = useState<boolean>(false);

  useEffect(() => {
    setLimitedItems(items.slice(0, limit));
  }, [items, limit]);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    // Check if the new focused element is not a descendant of the container
    if (!containerRef.current?.contains(e.relatedTarget)) {
      setInputFocused(false);
    } else {
      inputRef.current?.focus(); // Keep focus if it's a child element
    }
  };

  const handleItemClick = (item: any) => {
    setInputFocused(false);
    inputRef.current?.blur();
    onItemClick(item);
  };

  return (
    <div className="search-box" ref={containerRef}>
      <div className="search-box-input">
        {searchLabel && <Form.Label>{searchLabel}</Form.Label>}
        <Form.Control
          ref={inputRef}
          type="text"
          onChange={(e) => onChange(e.target.value)}
          onFocus={() => setInputFocused(true)}
          onBlur={handleBlur}
          placeholder="Search..."
        />
        <Form.Text className="text-input" />
        <Search className="search-icon" size={18} />
      </div>
      {inputFocused && (
        <div className="search-box-result-container">
          {limitedItems.length > 0 &&
            !isLoading &&
            limitedItems.map((item) => (
              <Button
                variant="secondary"
                className="list-item"
                key={item[itemKey]}
                onClick={() => handleItemClick(item)}
              >
                {item[itemDisplayKey] ?? `Unavailable ${itemDisplayKey}`}
              </Button>
            ))}
          {isLoading && (
            <div className="items-loading-container">
              <Spinner className="items-loading" animation="border" />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

SearchBox.defaultProps = {
  limit: 1000,
  isLoading: false,
};

export default SearchBox;
