import { useLocation } from 'react-router';
import React, { JSX, useEffect, useState } from 'react';
import Topbar from '../Topbar/Topbar';
import Loader from '../Loader/Loader';
import Sidebar from '../Sidebar/Sidebar';
import {
  selectErrorMessage,
  selectIsPageLoading,
  selectIsSidebarCollapsed,
  selectLoaderMessage,
  selectSuccessMessage,
  setErrorMessage,
  setSuccessMessage,
} from '../../features/layout/layout.slice';
import {
  setCurrentPbiReportId,
  setCurrentReportPageName,
} from '../../features/report/report.slice';
import packageJson from '../../../package.json';
import BsToast, { ToastType } from '../BsToast/BsToast';
import { useAppDispatch, useAppSelector } from '../../features/hooks';

export interface ILayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: ILayoutProps): JSX.Element {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const errorMessage = useAppSelector(selectErrorMessage);
  const loaderMessage = useAppSelector(selectLoaderMessage);
  const isPageLoading = useAppSelector(selectIsPageLoading);
  const successMessage = useAppSelector(selectSuccessMessage);
  const isSidebarCollapsed = useAppSelector(selectIsSidebarCollapsed);
  const [showError, setShowError] = useState<boolean>(false);
  const [showTopbar, setShowTopbar] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [messageTimer, setMessageTimer] = useState<NodeJS.Timeout | null>(null);

  const resetErrorMessage = () => {
    setShowError(false);
    setTimeout(() => {
      dispatch(setErrorMessage(''));
    }, 250);
  };

  const resetSuccessMessage = () => {
    setShowSuccess(false);
    setTimeout(() => {
      dispatch(setSuccessMessage(''));
    }, 250);
  };

  const displayErrorMessage = () => {
    setShowError(true);
    setMessageTimer(
      setTimeout(() => {
        resetErrorMessage();
      }, 5000)
    );
  };

  const displaySuccessMessage = () => {
    setShowSuccess(true);
    setMessageTimer(
      setTimeout(() => {
        resetSuccessMessage();
      }, 5000)
    );
  };

  const getSidebarState = () => {
    if (showSidebar) {
      return isSidebarCollapsed ? 'collapsed' : 'expanded';
    }
    return 'hidden';
  };

  useEffect(() => {
    if (successMessage) displaySuccessMessage();
    else if (errorMessage) displayErrorMessage();
    return () => {
      if (messageTimer) clearTimeout(messageTimer);
    };
  }, [errorMessage, successMessage]);

  // TODO: Add a list of paths where navbar not shown
  const checkLocation = (path: string) => {
    /* Rules to show the Sidebar */
    const showSide = ![
      '/signin',
      '/redirect',
      '/unauthorized',
      '/expired',
      '/server-error',
    ].includes(path);
    setShowSidebar(showSide);
    /* Rules to show the Topbar */
    const showTop =
      !['/signin', '/redirect', '/unauthorized', '/expired', '/server-error'].includes(path) &&
      !path.startsWith('/powerbi');
    setShowTopbar(showTop);

    /* Close any open report accordion and selected page */
    if (!path.startsWith('/powerbi')) {
      dispatch(setCurrentReportPageName(null));
      dispatch(setCurrentPbiReportId(null));
    }
  };

  useEffect(() => {
    checkLocation(location.pathname);
  }, [location.pathname]);

  return (
    <div data-version={packageJson.version}>
      <div className="page-layout">
        <div className={`page-container sidebar-state-${getSidebarState()}`}>
          {showTopbar && <Topbar />}
          {showSidebar && <Sidebar />}
          {!isPageLoading && <div className="page-content">{children}</div>}
          {isPageLoading && (
            <div id="layoutSpinner">
              <Loader message={loaderMessage} className="" />
            </div>
          )}
        </div>
      </div>
      <div className="notification">
        <BsToast
          message={errorMessage}
          show={showError}
          type={ToastType.DANGER}
          onClose={() => resetErrorMessage()}
          sidebarState={getSidebarState()}
        />
        <BsToast
          message={successMessage}
          show={showSuccess}
          type={ToastType.SUCCESS}
          onClose={() => resetSuccessMessage()}
          sidebarState={getSidebarState()}
        />
      </div>
    </div>
  );
}

export default Layout;
