import React from 'react';
import { Form } from 'react-bootstrap';
import IClient, { ICreateClientDto, IUpdateClientDto } from '../../types/client.d';

interface ClientPowerbiSettingsProps {
  client: IClient;
  clientUpdate: ICreateClientDto | IUpdateClientDto;
  setClientUpdate: React.Dispatch<
    React.SetStateAction<ICreateClientDto> | React.SetStateAction<IUpdateClientDto>
  >;
}

function ClientPowerbiSettings({
  client,
  clientUpdate,
  setClientUpdate,
}: ClientPowerbiSettingsProps) {
  const onTogglePBIEmbedded = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tenantId: string | null;
    let applicationId: string | null;
    const { checked } = event.target;
    if (!checked) {
      tenantId = null;
      applicationId = null;
    } else {
      tenantId = client.aadTenantId ?? '';
      applicationId = client.aadApplicationId ?? '';
    }
    setClientUpdate({
      ...clientUpdate,
      hasPBIEmbedded: checked,
      aadTenantId: tenantId,
      aadApplicationId: applicationId,
    });
  };

  return (
    <>
      <div className="client-powerbi-group">
        <Form.Label className="client-powerbi-label">Client name</Form.Label>
        <Form.Control
          type="text"
          defaultValue={client.name}
          onChange={(e) => setClientUpdate({ ...clientUpdate, name: e.target.value })}
          className="client-powerbi-input"
          maxLength={32}
          required
        />
      </div>
      <div className="client-powerbi-group">
        <Form.Label className="client-powerbi-label">Client code</Form.Label>
        <Form.Control
          type="text"
          defaultValue={client.code}
          onChange={(e) => setClientUpdate({ ...clientUpdate, code: e.target.value })}
          className="client-powerbi-input"
          maxLength={16}
          required
        />
      </div>
      <div className="client-powerbi-switch">
        <Form.Label className="client-powerbi-label">Has Power BI Embedded</Form.Label>
        <Form.Switch defaultChecked={client.hasPBIEmbedded} onChange={onTogglePBIEmbedded} />
      </div>
      {clientUpdate?.hasPBIEmbedded && (
        <>
          <div className="client-powerbi-group">
            <Form.Label className="client-powerbi-label">AAD Tenant ID</Form.Label>
            <Form.Control
              type="text"
              defaultValue={client.aadTenantId ?? ''}
              onChange={(e) => setClientUpdate({ ...clientUpdate, aadTenantId: e.target.value })}
              className="client-powerbi-input"
              required={clientUpdate.hasPBIEmbedded}
            />
          </div>
          <div className="client-powerbi-group">
            <Form.Label className="client-powerbi-label">AAD Application ID</Form.Label>
            <Form.Control
              type="text"
              defaultValue={client.aadApplicationId ?? ''}
              onChange={(e) =>
                setClientUpdate({ ...clientUpdate, aadApplicationId: e.target.value })
              }
              className="client-powerbi-input"
              required={clientUpdate.hasPBIEmbedded}
            />
          </div>
          <p id="app-secret-warn">
            Ensure the client&#39;s app secret has been added to Env Vars
            &#34;xxx_APPLICATION_SECRET&#34;
          </p>
        </>
      )}
    </>
  );
}

export default ClientPowerbiSettings;
