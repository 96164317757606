import { useRef, useState, FormEvent } from 'react';
import { Button, Form, Modal, ModalProps } from 'react-bootstrap';
import { useAddClientMutation } from '../../../../features/client/clientApi.slice';
import { useAppDispatch } from '../../../../features/hooks';
import { setErrorMessage, setSuccessMessage } from '../../../../features/layout/layout.slice';
import ClientPowerbiSettings from '../../../../components/ClientPowerbiSettings/ClientPowerbiSettings';
import IClient, { ICreateClientDto } from '../../../../types/client.d';

interface AddClientModalProps {
  modalProps: ModalProps;
  handleClose: () => void;
}

function AddNewClientModal({ modalProps, handleClose }: AddClientModalProps) {
  const { show, onHide, centered, closeButton } = modalProps;
  const dispatch = useAppDispatch();
  const [addClient] = useAddClientMutation();
  const formRef = useRef<HTMLFormElement | null>(null);
  const [newClient, setNewClient] = useState<ICreateClientDto>({
    name: '',
    code: '',
    hasPBIEmbedded: true,
    aadTenantId: '',
    aadApplicationId: '',
  });

  const submitNewClient = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    addClient(newClient)
      .unwrap()
      .then(() => {
        dispatch(setSuccessMessage('Client added succesfully'));
        handleClose();
      })
      .catch(() => dispatch(setErrorMessage('Unable to add client')));
  };

  return (
    <Modal id="addClientModal" show={show} onHide={onHide} centered={centered}>
      <Modal.Header closeButton={closeButton} className="border-0 p-0">
        <Modal.Title className="modal-title">New Client</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Form id="addNewClient" ref={formRef} onSubmit={submitNewClient}>
          <div className="new-client-fields">
            <div className="form-subtitle-container">
              <Form.Text className="form-subtitle">CLIENT DETAILS</Form.Text>
            </div>
            <ClientPowerbiSettings
              client={newClient as IClient}
              clientUpdate={newClient}
              setClientUpdate={setNewClient}
            />
          </div>
          <div className="form-button">
            <Button type="submit" variant="primary">
              ADD NEW CLIENT
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default AddNewClientModal;
