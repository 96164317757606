import { useMsal } from '@azure/msal-react';
import SignOutButton from '../../components/Buttons/SignOutButton';

function UnregisteredUser() {
  const { instance } = useMsal();
  const clientName = window.location.host.split('.')[0].toUpperCase();

  const name = instance.getActiveAccount()?.username;

  return (
    <div id="unregisteredUser">
      <h1>
        {name} does not have access to {clientName}
      </h1>
      <h5>Please, make sure you have been added to the platform.</h5>
      <div className="sign-out">
        <SignOutButton className="unauthorized-signout" variant="secondary">
          LOG OUT
        </SignOutButton>
        <p>Sign out from Microsoft</p>
      </div>
    </div>
  );
}

export default UnregisteredUser;
