import { JSX } from 'react';
import { Spinner } from 'react-bootstrap';

interface ILoaderProps {
  className: string;
  message: string;
}

function Loader(props: ILoaderProps): JSX.Element {
  const { className, message } = props;
  return (
    <div className="loader-component">
      <Spinner className={className} animation="border" />
      <div className="message">{message}</div>
    </div>
  );
}

export default Loader;
