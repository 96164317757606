import React, { FunctionComponent } from 'react';
import { Icon } from 'react-bootstrap-icons';
import { Link } from 'react-router';

interface IReportTileProps {
  name: string;
  linkTo: string;
  BsIcon: Icon | FunctionComponent;
}

function ReportTile(props: IReportTileProps) {
  const { name, linkTo, BsIcon } = props;

  return (
    <Link className="tile-outer-container" to={linkTo} style={{ textDecoration: 'none' }}>
      <div className="report-tile-container">
        <div className="report-header">
          <BsIcon className="report-icon" />
          <span className="report-name">{name}</span>
        </div>
      </div>
    </Link>
  );
}

export default ReportTile;
